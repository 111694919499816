import styled from "styled-components"
import React from "react"
import { Link } from "gatsby"

import { useArticles } from "~/hooks/use-articles"
import BackIcon from "~/icons/Nav_Back_Arrow.inline.svg"
import Header from "~/components/Header"
import Navigation from "~/components/Navigation"
import SocialBar from "~/components/SocialBar"

const Main = styled.main`
  margin-left: auto;
  margin-right: auto;
  margin-top: 71px;
`

const Footer = styled.footer`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  padding: 60px var(--content-padding-vertical);
  max-width: var(--content-max-width);
  margin-left: auto;
  margin-right: auto;
`

const workTitle = "Work"
const links = [
  {
    title: workTitle,
    to: "/#work",
  },
  {
    title: "About",
    to: "/about",
  },
]

export default function Layout({
  children,
  socialBar = true,
  backButton = false,
}) {
  const articles = useArticles()
  const hasFooter = socialBar || backButton
  const workLink = links.find(({ title }) => title === workTitle)
  workLink.sublinks = articles.map(({ node }) => ({
    to: node.slug,
    title: node.shortTitle,
  }))

  return (
    <>
      <Header
        title={
          <StyledLink className="secondary no-underline" to="/">
            Angela Corrado
          </StyledLink>
        }
        navigation={<Navigation links={links} />}
      />
      <Main>{children}</Main>
      {hasFooter ? (
        <Footer>
          {backButton ? (
            <BackButtonLink to={"/"}>
              <BackIcon />
            </BackButtonLink>
          ) : null}
          {socialBar ? <PaddedSocialBar /> : null}
        </Footer>
      ) : null}
    </>
  )
}

const BackButtonLink = styled(Link)`
  margin-right: auto;
`

const PaddedSocialBar = styled(SocialBar)`
  margin-right: auto;

  &:first-child {
    margin-left: auto;
  }
`

const StyledLink = styled(Link)`
  font-family: var(--high-emphasis-font-family);
  font-weight: var(--high-emphasis-font-weight);
  text-transform: uppercase;
`
