import React from "react"
import styled from "styled-components"

export default function Dropdown({ trigger, content }) {
  return (
    <TriggerContainer>
      {trigger}
      <ContentContainer>{content}</ContentContainer>
    </TriggerContainer>
  )
}

const TriggerContainer = styled.div`
  position: relative;
`

const ContentContainer = styled.div`
  display: none;
  position: absolute;
  bottom: 0;
  right: 0;
  transform: translateY(100%);

  ${TriggerContainer}: hover & {
    display: block;
  }
`
