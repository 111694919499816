import { graphql, useStaticQuery } from "gatsby"

export const useArticles = () => {
  const { allContentfulArticle } = useStaticQuery(graphql`
    query AllArticles {
      allContentfulArticle {
        edges {
          node {
            title
            shortTitle
            slug
          }
        }
      }
    }
  `)
  return allContentfulArticle.edges
}
