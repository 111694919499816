import React from "react"
import styled from "styled-components"

export default function Header({ title, navigation }) {
  return (
    <Backdrop>
      <StyledHeader>
        <div>{title}</div>
        <div className="right-aligned">{navigation}</div>
      </StyledHeader>
    </Backdrop>
  )
}

const Backdrop = styled.div`
  background-color: var(--header-background-color);
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  z-index: 1;
`

const StyledHeader = styled.header`
  padding: var(--quadruple-spacing) var(--content-padding-vertical)
    var(--double-spacing) var(--content-padding-vertical);
  max-width: var(--content-max-width);
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-flow: row nowrap;
`
