import React from "react"
import styled from "styled-components"

import LinkedInIcon from "~/icons/Social_LinkedIn_Icon.inline.svg"
import MailIcon from "~/icons/Social_Mail_Icon.inline.svg"
import InstagramIcon from "~/icons/Social_Instagram_Icon.inline.svg"
import MediumIcon from "~/icons/Social_Medium_Icon.inline.svg"

export default function SocialBar(props) {
  return (
    <SocialBarContainer {...props}>
      <SocialBarIcon href="mailto:acorradodesign@gmail.com">
        <MailIcon />
      </SocialBarIcon>
      <SocialBarIcon href="https://www.instagram.com/corradoang">
        <InstagramIcon />
      </SocialBarIcon>
      <SocialBarIcon href="https://medium.com/@angelamcorrado">
        <MediumIcon />
      </SocialBarIcon>
      <SocialBarIcon href="https://www.linkedin.com/in/angelacorrado/">
        <LinkedInIcon />
      </SocialBarIcon>
    </SocialBarContainer>
  )
}

function SocialBarIcon({ children, href }) {
  return (
    <IconLink href={href} className="secondary" rel="noopener noreferrer">
      {children}
    </IconLink>
  )
}

const SocialBarContainer = styled.div`
  --margin: var(--single-spacing);

  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
`

const IconLink = styled.a`
  --icon-size: 41px;

  flex: 0 0 var(--icon-size);
  width: var(--icon-size);
  height: var(--icon-size);
  color: var(--secondary);
  margin: var(--margin);

  & > svg > path {
    fill: currentColor;
  }
`
