import { Link } from "gatsby"
import React from "react"
import styled from "styled-components"

import Dropdown from "~/components/Dropdown"

export default function Navigation({ links }) {
  return (
    <Nav>
      <ul>
        {links.map((link, index) => (
          <li key={index}>
            <NavigationLink {...link} />
          </li>
        ))}
      </ul>
    </Nav>
  )
}

const Nav = styled.nav`
  & > ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    & > li {
      display: inline-block;
    }
  }
`

const NavigationLink = ({ title, to, sublinks = [] }) => {
  if (sublinks.length) {
    return (
      <Dropdown
        trigger={
          <StyledLink className="secondary no-underline" to={to}>
            {title}
          </StyledLink>
        }
        content={
          <DropdownBox>
            <DropdownContent>
              {sublinks.map(({ title, to }, index) => (
                <li key={index}>
                  <Link className="secondary no-underline" to={to}>
                    {title}
                  </Link>
                </li>
              ))}
            </DropdownContent>
          </DropdownBox>
        }
      />
    )
  } else {
    return (
      <StyledLink className="secondary no-underline" to={to}>
        {title}
      </StyledLink>
    )
  }
}
const DropdownBox = styled.div`
  padding-top: var(--double-spacing);
`

const DropdownContent = styled.ul`
  padding: var(--single-spacing);
  text-align: right;
  white-space: nowrap;
  list-style-type: none;
  margin: 0;
  display: block;
  background-color: var(--header-background-color);

  li a {
    text-transform: uppercase;
    font-family: var(--low-emphasis-font-family);
    font-weight: var(--low-emphasis-font-weight);
  }
  text-transform: uppercase;
`

const StyledLink = styled(Link)`
  margin: 0 0 0 calc(var(--single-spacing) * 7);
  text-transform: uppercase;
  font-family: var(--high-emphasis-font-family);
  font-weight: var(--high-emphasis-font-weight);
  padding-right: 8px;

  &:last-child {
    padding-right: 0px;
  }

  @media screen and (max-width: 576px) {
    margin-left: calc(var(--single-spacing) * 3);
  }
`
